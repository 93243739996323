// import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import TextTransition, { presets } from "react-text-transition";
import "animate.css";
import { isAndroid, isIOS } from "react-device-detect";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";

import { db } from "../firebase-config";
import Header from "../components/Header.js";
import FeatureBrief from "../components/FeatureBrief.js";
import AboutUs from "../components/AboutUs.js";
import "./Home.css";

const TEXTS = [
  { text: "scary", emoji: " 😨" },
  { text: "anxiety", emoji: " 😰" },
  { text: "not it", emoji: " 🙄" },
];

function Home() {
  const [index, setIndex] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [motion, setMotion] = useState(
    window.matchMedia("(prefers-reduced-motion: no-preference)").matches
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setMotion(false);
      }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1750 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const openIos = () => {
    window.open(
      `https://apps.apple.com/us/app/gymbuddy-match-workout/id6446038725`
    );
  };
  const openAndroid = () => {
    window.open(
      `https://play.google.com/store/apps/details?id=com.gymbuddystudent`
    );
  };

  const onDownloadPress = async () => {
    const downloadLinkClicksRef = collection(db, "gbWaitlist");
    console.log("waitlistclicked2");
    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        createdAt: serverTimestamp(),
        buttonLocation: "landing",
        os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
      });
      // window.location.replace("https://forms.gle/VfNiYs2qAvnwmmyXA");
      window.location.replace(
        "https://apps.apple.com/us/app/gymbuddy-connect-workout/id6446038725"
      );
      // Note: Using window.open prevents it from being opened when website is opened from ig
      // window.open(
      //   "https://apps.apple.com/us/app/gymbuddy-connect-workout/id6446038725",
      //   "_blank",
      //   "noreferrer"
      // );
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <main className="homepage">
      <Header homeScreen={"t"} />
      <div className="side-padding landing-container">
        <div className="slide-up">
          <h1>
            <span className="fw-bold titley">Because gyming </span> <br />
            <span className="fw-bold titley">alone is</span>
            <br />
            {motion ? (
              <TextTransition
                springConfig={presets.wobbly}
                style={{
                  height: "auto",
                }}
              >
                <span className="titley-gradient fw-bold">
                  {TEXTS[index % TEXTS.length].text}
                  <span className="titley fw-bold">
                    {TEXTS[index % TEXTS.length].emoji}
                  </span>
                </span>
              </TextTransition>
            ) : (
              <span className="titley-gradient fw-bold">
                scary
                <span className="titley fw-bold"> 😨</span>
              </span>
            )}
          </h1>

          <p className="subTitley">
            Get paired up and start your fitness journey today!
          </p>
          <button
            onClick={onDownloadPress}
            className="landing-download-button"
            aria-label="Open link for mobile download"
          >
            Try GymBuddy
            <span
              style={{
                backgroundColor: "#eaebeb",
                width: 40,
                height: 40,
                borderRadius: 20,
                padding: 10,
                marginLeft: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowRight color="#2b2e32" className="arrow-right" alt="" />
            </span>
          </button>
        </div>

        <div
          className="slide-up right-column"
          style={{
            height: "min-content",
          }}
        >
          <img
            className="home-image"
            src={require("../assets/gg.png")}
            alt="Two GymBuddy users taking a mirror selfie at the gym"
          />
          <img
            className={`${
              motion
                ? "animate__animated animate__swing animate__infinite	infinite"
                : ""
            }`}
            style={{
              objectFit: "contain",
              position: "absolute",
              top: 0,
              left: 0,
              width: "25%",
              height: "auto",
            }}
            src={require("../assets/mKettle.png")}
            alt=""
          />
          <img
            style={{
              objectFit: "contain",
              width: "35%",
              height: "auto",
              bottom: 0,
              right: 0,
              position: "absolute",
            }}
            src={require("../assets/mDumbbell.png")}
            alt=""
          />
        </div>
      </div>
      <FeatureBrief />
      <AboutUs />
    </main>
  );
}

export default Home;
