import React, { useEffect, useState } from "react";
import { Table, Modal, Accordion } from "react-bootstrap";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  // getCountFromServer,
} from "firebase/firestore";
import moment from "moment";
import { useSearchParams, useNavigate } from "react-router-dom";

import { db } from "../firebase-config";

function Fliers() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [scans, setScans] = useState([]);
  const [counts, setCounts] = useState([]);
  // const [scansByCampaign, setScansByCampaign] = useState([]);
  //example link:
  //https://getgymbuddy.com/fliers/?utm_source=admindashboardkey6969696969akdhfkalsdhfadshflauhlfuahdsfad8f7asd79fa8dfa7dfads7fyds7ayfa8df6af6agdasfae673gfoeagilhf83ah8fah3w8fh3aw8f9aw3hf38awhf3aw8hf3a8whfaw38f3adsfadsfadsfaefnakjwefeafaenfewfaewf
  useEffect(() => {
    const urlSource = searchParams.get("utm_source");
    const key =
      "admindashboardkey6969696969akdhfkalsdhfadshflauhlfuahdsfad8f7asd79fa8dfa7dfads7fyds7ayfa8df6af6agdasfae673gfoeagilhf83ah8fah3w8fh3aw8f9aw3hf38awhf3aw8hf3a8whfaw38f3adsfadsfadsfaefnakjwefeafaenfewfaewf";
    if (urlSource === key) {
      const scansCollectionRef = collection(db, "gymbuddyQrScans");

      const q = query(
        scansCollectionRef,
        // where("status", "==", "default"),
        // orderBy("createdAt", "asc"),
        limit(500),
        // limit(100),
        orderBy("createdAt", "desc")
      );
      const getScans = async () => {
        const data = await getDocs(q);
        setScans(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        // console.log(
        //   "scans",
        //   data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // );

        //for each scan - if campaign exists in the campaigns array add it to scans field
        //if campaign doesn't exist, add new campaign (and fields)

        const campaignTypes = data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .map((dataItem) => dataItem.utm_campaign) // get all media types
          .filter(
            (campaignType, index, array) =>
              array.indexOf(campaignType) === index
          ); // filter out duplicates

        const counts = campaignTypes.map((campaignType) => ({
          type: campaignType,
          count: data.docs
            .map((doc) => ({ ...doc.data(), id: doc.id }))
            .filter((item) => item.utm_campaign === campaignType).length,
        }));

        setCounts(counts);

        // console.log("campaignTypes", campaignTypes, "counts", counts);
      };

      getScans();
    }
  }, []);

  return (
    <>
      {scans.length > 0 && (
        <div>
          Fliers
          <div>Total Scans: {scans.length}</div>
          <div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Key</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <img
                      style={{
                        objectFit: "contain",
                        height: 200,
                        // borderRadius: 10,
                        // marginTop: 20,
                        marginBottom: 10,
                        //   margin: "auto    ",
                      }}
                      src={require("../assets/fliersMap.jpg")}
                      // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
                      alt=""
                    />
                  </div>
                  <div>1 = Arc tabling</div>
                  <div>v1 = 1 in elevator (Matt + Olivia)</div>
                  <div>v2 = 1 in elevator (Matt + Olivia + UC Davis)</div>
                  <div>v3 = 1 in elevator (Karan + Ji)</div>
                  <div>v4 = 1 (Plain qr code in elevator)</div>
                  <div>v5 = v1 in every room (Matt + Olivia)</div>
                  <div>v6 = v2 in every room (Matt + Olivia + UC Davis)</div>
                  <div>v7 = v3 in every room (Karan + Ji)</div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <Table striped bordered hover responsive>
            <thead className="stickyHead">
              <tr className="stickyHead">
                {counts.map((count) => (
                  <th className="stickyHead">
                    {count.type ? count.type : "unknown"}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {counts.map((count) => (
                  <td>{count.count}</td>
                ))}
              </tr>
            </tbody>
          </Table>
          <Table striped bordered hover responsive>
            <thead className="stickyHead">
              <tr className="stickyHead">
                <th className="stickyHead">Date</th>
                <th className="stickyHead">Time</th>
                <th className="stickyHead">Campaign</th>
                <th className="stickyHead">OS</th>
                <th className="stickyHead">Email</th>
                <th className="stickyHead">Attempted Emails</th>
              </tr>
            </thead>
            <tbody>
              {scans.map((scan) => (
                <tr>
                  <td>{scan?.createdAt?.toDate().toDateString()}</td>
                  <td>{scan?.createdAt?.toDate().toLocaleTimeString()}</td>
                  <td>{`${scan?.utm_source}, ${scan?.utm_medium}, ${scan?.utm_campaign}`}</td>
                  <td>{scan?.os}</td>
                  <td>{scan?.email}</td>
                  <td>
                    {scan?.attemptedEmails
                      ? scan?.attemptedEmails.map((email) => (
                          <p style={{ marginTop: 0, marginBottom: 0 }}>
                            {email}
                          </p>
                        ))
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
}

export default Fliers;
