import React from "react";
import "./AboutUs.css";
function AboutUs() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show-about-us");
      }
    });
  });
  const hiddenElements = document.querySelectorAll(".hidden");
  hiddenElements.forEach((el) => observer.observe(el));
  return (
    <div className="about-us">
      <h4 className="fw-bold titley side-padding">About Us</h4>
      <div className="about-us-body hidden">
        <img
          style={{
            objectFit: "contain",
            maxWidth: "100%",
            width: 400,
            height: "auto",
            borderRadius: 20,
          }}
          loading="lazy"
          src={require("../assets/founders.jpg")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt="The founders of GymBuddy, Eugene Liu and Matthew Bagale"
        />
        <div className="about-us-text">
          <p className="subTitley">
            GymBuddy was created by Eugene Liu and Matthew Bagale while they
            were students at UC Davis. They hated how intimidating it felt going
            to the gym alone and they would often skip the gym entirely if they
            couldn’t find someone to go with.
          </p>
          <p className="subTitley">
            So they launched GymBuddy—Eugene coded the first version of the app
            in two weeks, Matthew presented it to one of his classes, and
            GymBuddy took off from there!
          </p>
          <p className="subTitley">
            After creating thousands of pairs of student gymbuddies across
            Stanford, UC Berkeley, and UC Davis, we are now opening to the San
            Francisco public with the mission of helping millions more overcome
            gym anxiety!
          </p>
        </div>
      </div>
    </div>
  );
}
export default AboutUs;
