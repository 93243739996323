import { useEffect } from "react";
// import { Container, Row, Col, Stack } from "react-bootstrap";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { isAndroid, isIOS } from "react-device-detect";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./Download.css";
import { db } from "../firebase-config";

//example link:
//https://getgymbuddy.com/download/?utm_source=test&utm_medium=test&utm_campaign=test
//https://getgymbuddy.com/download/?utm_source=pres&utm_medium=monlec&utm_campaign=1
//https://getgymbuddy.com/download/?utm_source=ig&utm_medium=story&utm_campaign=sol

function Download() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("useeffect runnign");
    // console.log("searchParams", searchParams.get("utm_source"));
    // console.log("searchParams", searchParams.get("utm_medium"));
    // console.log("searchParams", searchParams.get("utm_campaign"));
    const qrScansRef = collection(db, "gymbuddyQrScans");

    const recordQrScan = async () => {
      try {
        const newScan = await addDoc(qrScansRef, {
          createdAt: serverTimestamp(),
          os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
          utm_source: searchParams.get("utm_source"),
          utm_medium: searchParams.get("utm_medium"),
          utm_campaign: searchParams.get("utm_campaign"),
        });

        //auto navigate
        if (isAndroid) {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.gymbuddystudent"
          );
        }
        if (isIOS) {
          window.location.replace(
            "https://apps.apple.com/us/app/gymbuddy-match-workout/id6446038725"
          );
        }
      } catch (error) {
        console.log("eror", error);
      }
    };
    recordQrScan();
  }, []);

  const appStoreClick = async () => {
    console.log("appstoreclick");
    //record click in db
    //redirect to app store
    const downloadLinkClicksRef = collection(db, "downloadLinkClicks");

    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        buttonType: "ios",
        createdAt: serverTimestamp(),
      });
      window.location.replace(
        "https://apps.apple.com/us/app/gymbuddy-match-workout/id6446038725"
      );
    } catch (error) {
      console.log("eror", error);
    }
  };
  const playStoreClick = async () => {
    console.log("playstoreclick");
    //record click in db
    //redirect to app store
    const downloadLinkClicksRef = collection(db, "downloadLinkClicks");

    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        buttonType: "android",
        createdAt: serverTimestamp(),
      });

      //replace the app store link below with the Play Store link
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.gymbuddystudent"
      );

      // navigate("/waitlist");
    } catch (error) {
      console.log("eror", error);
    }
  };

  return (
    <div style={{ paddingTop: 200, minHeight: "100vh" }}>
      <div className="centeredDiv">
        <img
          style={{
            objectFit: "contain",
            height: 50,
            borderRadius: 10,
            marginTop: 20,
          }}
          src={require("../assets/icon.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      <div className="centeredDiv">
        <img
          onClick={appStoreClick}
          className="appButton"
          style={{
            objectFit: "contain",
            maxWidth: "90%",
            width: 250,
            marginTop: 25,
          }}
          src={require("../assets/appStoreLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      <div className="centeredDiv">
        <img
          onClick={playStoreClick}
          className="appButton"
          style={{
            objectFit: "contain",
            maxWidth: "90%",
            width: 250,
            marginTop: 20,
            //   top: "50%",
            //   margin: "auto",
          }}
          src={require("../assets/playLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
    </div>
  );
}

export default Download;
