import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  increment,
} from "firebase/firestore";
import { isAndroid, isIOS } from "react-device-detect";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./WeeklyPairings.css";
import { db } from "../firebase-config";

export default function WeeklyPairings() {
  const [searchParams, setSearchParams] = useSearchParams();

  const statsRef = doc(db, "weeklyPairingsLandingPage", "stats");
  const qrScansRef = collection(db, "gymbuddyWPQrScans");

  //example link:
  //https://getgymbuddy.com/weeklypairings/?utm_source=tabling&utm_medium=silo&utm_campaign=wp1

  useEffect(() => {
    console.log("run");

    const recordView = async () => {
      try {
        await updateDoc(statsRef, {
          pageViews: increment(1),
          // pageViews: 1,
        });
      } catch (error) {
        console.log("eror", error);
      }
    };
    const recordQrScan = async () => {
      if (searchParams.get("utm_source")) {
        try {
          const newScan = await addDoc(qrScansRef, {
            createdAt: serverTimestamp(),
            os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
            utm_source: searchParams.get("utm_source"),
            utm_medium: searchParams.get("utm_medium"),
            utm_campaign: searchParams.get("utm_campaign"),
          });
        } catch (error) {
          console.log("eror", error);
        }
      }
    };
    recordView();
    recordQrScan();
  }, []);

  const handleClickedQ1 = async () => {
    try {
      await updateDoc(statsRef, {
        clickedQ1: increment(1),
      });
    } catch (error) {
      console.log("eror", error);
    }
  };
  const handleClickedQ2 = async () => {
    try {
      await updateDoc(statsRef, {
        clickedQ2: increment(1),
      });
    } catch (error) {
      console.log("eror", error);
    }
  };
  const handleClickedQ3 = async () => {
    try {
      await updateDoc(statsRef, {
        clickedQ3: increment(1),
      });
    } catch (error) {
      console.log("eror", error);
    }
  };
  const handleClickedQ4 = async () => {
    try {
      await updateDoc(statsRef, {
        clickedQ4: increment(1),
      });
    } catch (error) {
      console.log("eror", error);
    }
  };
  const handleClickedGetPairedButton = async () => {
    try {
      await updateDoc(statsRef, {
        clickedGetPairedButton: increment(1),
      });
    } catch (error) {
      console.log("eror", error);
    }
  };

  const openForm = () => {
    handleClickedGetPairedButton();
    window.open(`https://forms.gle/Tgyuq8THjappfTh77`);
  };

  return (
    <div
      className="privacyPolicyDiv"
      style={{
        minHeight: "100vh",
        maxWidth: 700,
        // backgroundColor: "red",
        margin: "auto",
      }}
    >
      <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   backgroundColor: "red",
        // }}
        style={{ textAlign: "center" }}
      >
        <img
          style={{
            objectFit: "contain",
            height: 50,
            borderRadius: 10,
            // marginTop: 20,
            marginBottom: 10,
            //   margin: "auto    ",
          }}
          src={require("../assets/icon.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      {/* <h1 className="privacyParagraph">Welcome to Gymbuddy Support</h1> */}
      <h1
        className="title bold-text gray-text marginBottom"
        style={{ marginTop: 40 }}
      >
        Get paired with a new gymbuddy{" "}
        <span className="gradient-text">every week</span>
      </h1>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="primary"
          size="lg"
          style={{ alignSelf: "center", marginTop: 20, marginBottom: 20 }}
          onClick={openForm}
        >
          Get Paired →
        </Button>{" "}
      </div>
      {/* <p className="privacyParagraph boldTitle">Contact Us</p> */}
      <h1 className="privacyParagraph" style={{ marginTop: 40 }}>
        How it Works
      </h1>
      <p className="privacyParagraph">1. Click the "Get Paired" button above</p>
      <p className="privacyParagraph">
        2. Fill out a short questionnaire with your gymbuddy preferences
      </p>
      <p className="privacyParagraph">
        3. Get paired with a new workout partner every Monday (on an opt in
        basis)
      </p>
      <h1 className="privacyParagraph" style={{ marginTop: 40 }}>
        FAQs
      </h1>
      <div style={{ marginBottom: 40 }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Why did we create Weekly Pairings?
            </Accordion.Header>
            <Accordion.Body onEnter={handleClickedQ1}>
              <p>
                In only our first month, the Gymbuddy app has successfully
                paired over 100 UC Davis students as gymbuddies, but we love
                constantly experimenting with new ways to help EVERY student
                find a gymbuddy!
              </p>
              {/* <p>
              Through Weekly Pairings, you fill out a short survey that lets
              Gymbuddy know what traits you are looking for in a work out
              partner, for example how experienced you want them to be at
              lifting.
            </p> */}
              {/* <p>
              We hope Weekly Pairings will allow you to make new friends every
              week, and with our no-pressure opt in system, you can choose which
              weeks you want to be paired!
            </p> */}
              {/* <p>
              We hope Weekly Pairings will allow you to make new friends every
              week, and if you end up finding the perfect gymbuddy that you want
              to work out with every day, our opt in system means there is no
              pressure to continue opting in to be paired up with someone new
              every week!
            </p> */}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How does the pairing algorithm work?
            </Accordion.Header>
            <Accordion.Body onEnter={handleClickedQ2}>
              {/* <p>
                The Weekly Pairings questionnaire first asks you to select the
                gender you are comfortable working out with—you will only be
                paired with another UC Davis student that is the gender you
                select.
              </p>
              <p>
                Next, it asks questions such as how experienced you prefer your
                gym partner to be at lifting and whether you prefer someone that
                is the same height and weight as you.
              </p>
              <p>
                For each question, you can decide whether your preference is a
                deal breaker or not, and the algorithm will then pair you up
                with the most compatible gymbuddy!
              </p> */}
              <p>
                Fill out the Weekly Pairings questionnaire to select the gender
                you are comfortable working out with and all of your other
                preferences. You get to decide whether each preference is a
                dealbreaker or not, and the algorithm will pair you up with the
                most compatible student!
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              I filled out the questionnaire. How do I get my gymbuddy?
            </Accordion.Header>
            <Accordion.Body onEnter={handleClickedQ3}>
              <p>
                Pairings occur each Monday, where Gymbuddy will text you your
                new workout partner’s contact information so the two of you can
                hit the gym together!
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How do I opt in to be matched again?
            </Accordion.Header>
            <Accordion.Body onEnter={handleClickedQ4}>
              <p>
                We will send an email each Sunday that will allow you to opt in.
                By default, you will not be paired up the following week if you
                do not click opt in.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
