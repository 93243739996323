import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { Container, Row, Col, Stack } from "react-bootstrap";

import { useSearchParams, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { isAndroid, isIOS } from "react-device-detect";

import "./WeeklyPairings.css";
import { db } from "../firebase-config";

export default function WeeklyPairings() {
  useEffect(() => {
    console.log("useeffect runnign");
    // console.log("searchParams", searchParams.get("utm_source"));
    // console.log("searchParams", searchParams.get("utm_medium"));
    // console.log("searchParams", searchParams.get("utm_campaign"));
    const internshipPageViewsRef = collection(db, "gymbuddyInternshipScans");

    const recordQrScan = async () => {
      try {
        const newScan = await addDoc(internshipPageViewsRef, {
          createdAt: serverTimestamp(),
          os: isAndroid ? "android" : isIOS ? "ios" : "computer",
        });
      } catch (error) {
        // console.log("eror", error);
      }
    };
    recordQrScan();
  }, []);

  return (
    <div
      className="privacyPolicyDiv"
      style={{
        minHeight: "100vh",
        maxWidth: 700,
        // backgroundColor: "red",
        margin: "auto",
      }}
    >
      <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   backgroundColor: "red",
        // }}
        style={{ textAlign: "center" }}
      >
        <img
          style={{
            objectFit: "contain",
            height: 50,
            borderRadius: 10,
            // marginTop: 20,
            marginBottom: 10,
            //   margin: "auto    ",
          }}
          src={require("../assets/icon.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      {/* <h1 className="privacyParagraph">Welcome to Gymbuddy Support</h1> */}

      <h1
        className="privacyParagraph"
        style={{ marginTop: 40, textAlign: "left" }}
      >
        Internship Positions{" "}
      </h1>
      <p className="privacyParagraph">
        GymBuddy is currently offering unpaid internships in the following
        categories:
      </p>
      {/* Software Engineering */}
      {/* <p className="privacyParagraph">
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          -Software Engineering:
        </span>{" "}
        During your internship, you will pursue projects with Gymbuddy, Inc. to
        help you develop skills in the software engineering field. Two major
        projects we are currently offering to interns are:
        <p style={{ paddingLeft: 10, paddingTop: 10 }}>
          (1) Revamping the{" "}
          <a href="https://getgymbuddy.com/">GymBuddy website</a>: Our
          engineering focus thus far has been solely dedicated to making the
          GymBuddy mobile app work the best for students as possible— our
          website on the other hand, could use some work! If you are interested
          in turning it from the eyesore that it currently is, into something
          amazing, this is your opportunity to hone your web dev skills on a
          website that is already live and used by new students everyday!
        </p>
        <p style={{ paddingLeft: 10 }}>
          (2) Developing the advertiser dashboard: Our current and future
          advertisers need a dashboard that enables them to easily create and
          manage ads as well as view their ads' performance. We have already
          onboarded as an advertiser a national athletic apparel retailer with
          over 250+ stores in the US. Help shape the future of GymBuddy’s
          advertising platform!
        </p>
      </p> */}

      <p className="privacyParagraph">
        {" "}
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          - Growth and Marketing:
        </span>{" "}
        During your internship, you will pursue projects with Gymbuddy, Inc. to
        help you develop skills in the area of marketing. Projects will include
        developing and executing marketing strategies to spread GymBuddy across
        San Francisco. Our goal with this internship is to give students in this
        field the opportunity to test marketing strategies and learn about their
        effectiveness in a startup context.
      </p>
      {/* <p className="privacyParagraph">
        {" "}
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          - Sales Development:
        </span>{" "}
        During your internship, you will pursue projects with Gymbuddy, Inc. to
        help you develop skills in the area of sales development. Projects will
        include developing and executing sales strategies to spread GymBuddy
        across campus and grow our b2b operations. Opportunities will include
        presenting in classes, tabling, speaking to small business owners, and
        negotiating with other corporations.
      </p> */}
      <p className="privacyParagraph">
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          - Graphic Design:
        </span>{" "}
        During your internship, you will pursue projects with Gymbuddy, Inc. to
        help you develop skills in the area of graphic design. Projects will
        include designing marketing materials for social media, presentations,
        and events. Our goal with this internship is to give students in this
        field the opportunity to build their portfolio of design work applied to
        real world scenarios.
      </p>
      <h1
        className="privacyParagraph"
        style={{ marginTop: 40, textAlign: "left" }}
      >
        Application Process
      </h1>
      <p className="privacyParagraph">
        Please email your resume and the position you are interested in to
        hellogymbuddy@gmail.com to set up an interview over Zoom! (For the
        Graphic Design position, please also include a portfolio of your work).
      </p>
      <h1
        className="privacyParagraph"
        style={{ marginTop: 40, textAlign: "left" }}
      >
        About the Founders{" "}
      </h1>
      <p className="privacyParagraph">
        We are two students that made GymBuddy because we needed it ourselves!
        Since launching in March, we have created over 1,000 pairs of gymbuddies
        across UC Davis, UC Berkeley, and Stanford campuses!
      </p>
      <img
        style={{
          objectFit: "contain",
          // height: 200,
          width: "100%",
          // borderRadius: 10,
          // marginTop: 20,
          marginBottom: 10,
          //   margin: "auto    ",
        }}
        src={require("../assets/founders.jpg")}
        // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
        alt=""
      />
      <Row>
        <Col>
          <p style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
            Eugene Liu
          </p>
          <p>-Graduated college in 2 years</p>
          <p>-Started law school at 19 yrs old</p>
          <p>
            -Featured in{" "}
            <a href="https://wwd.com/business-news/business-features/threadfunder-free-crowdfunding-website-launches-clothing-designers-1235256398/">
              WWD
            </a>{" "}
            and{" "}
            <a href="https://www.youtube.com/watch?v=0Q3lDKjYYD4&ab_channel=GoodDaySacramento">
              CBS News
            </a>{" "}
            for coding a crowdfunding website for clothing designers in five
            days
          </p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
            Matthew Bagale
          </p>
          <p>-Manager @Amazon</p>
          <p>
            -Collegiate national rowing champion and 3x State Rowing Champion in
            Texas
          </p>

          <p>-Featured in Vogue for runway modeling</p>
        </Col>
      </Row>
      <h1
        className="privacyParagraph"
        style={{ marginTop: 40, textAlign: "left" }}
      >
        Join Our Intern Team{" "}
      </h1>
      <p className="privacyParagraph" style={{ marginBottom: 40 }}>
        If you are passionate about our mission of helping people overcome gym
        anxiety, we welcome you to apply!
      </p>
      <img
        style={{
          objectFit: "contain",
          // height: 200,
          width: "100%",
          // borderRadius: 10,
          // marginTop: 20,
          marginBottom: 10,
          //   margin: "auto    ",
        }}
        src={require("../assets/interns.jpg")}
        // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
        alt=""
      />
    </div>
  );
}
